import React from "react"

import styled from "styled-components"
import Layout from "../components/layout"
import BackgroundImage from "gatsby-background-image"
import SEO from "../components/seo"
import lsnifaLogo from "../images/lsnifa-logo-inverse.svg"


import { ScreenWrapper, TextWrapper, FlexWrapper, FlagWrapper } from "../components/contentwrappers"
import ActionButton from "../components/actionbutton"
import ScrollButton from "../components/scrollbutton"

import { respondTo } from "../_respondTo"

const LogoWrapper = styled.div`
  color:white;
  background-color: rgba(0,42,108,0.8);
  text-align: center;
  padding: 30px;
  border-radius: 0.5em;

  font-family: 'Open Sans';
  font-weight: 300;

  margin: 0 auto;
  max-width:800px;

  ${respondTo.md`
    
  `}

  `

const Straline = styled.p`
  font-size: 1.5em;
`


const IndexPage = props => (
  <>
    <BackgroundImage
      fluid={props.data.royalCourtsImage.childImageSharp.fluid}
      alt="The Royal Courts of Justice"
      style={{ height: "100vh", width: "100vw", position: "fixed", top: "0", left: "0", zIndex: "-9" }}
    >
    </BackgroundImage>


    <Layout>
      <SEO title="Home" />


      <ScreenWrapper>
        <LogoWrapper>
          <img src={lsnifaLogo} alt="LSNIFA logo" style={{ display: "block", margin: "0 auto 30px auto" }}></img>
          <Straline>Law Society (NI) Financial Advice working hard for <strong>solicitors</strong>, their <strong>families</strong> and their <strong>clients</strong> since 1989</Straline>
        </LogoWrapper>

        <div style={{ textAlign: "center" }}>
          <ScrollButton toRef="/#AboutSection"></ScrollButton>
        </div>

      </ScreenWrapper>


      <ScreenWrapper id="AboutSection">
        <TextWrapper>
          <h1><strong>About</strong> Us</h1>
          <p>Law Society (NI) Financial Advice Ltd (LSNIFA) was established in 1989 by the Law Society of Northern Ireland to serve the needs of solicitors, their clients and their families.</p>
          <div style={{ textAlign: "center" }}>
            <ActionButton toRef="/about">Find out <strong>More</strong></ActionButton>
          </div>
        </TextWrapper>

        <div style={{ textAlign: "center" }}>
          <ScrollButton toRef="/#ServicesSection"></ScrollButton>
        </div>

      </ScreenWrapper>

      <ScreenWrapper id="ServicesSection">
        <TextWrapper>
          <h1>Services for Our <strong>Clients</strong></h1>
          <p>At LSNIFA we have developed a number of bespoke propositions for our clients. All have the same quality hallmarks and characteristics of delivering a value for money proposition tailored to your needs underpinned by institutional quality solutions and impartial, expert advice.</p>
        </TextWrapper>

        <FlexWrapper>
          <FlagWrapper>
            <h2><strong>Private Client</strong> Proposition</h2>
          Our flagship propositon offering institutional quality financial planning and wealth management.

            <ActionButton toRef="/services-for-clients#private-client-proposition">Find out <strong>More</strong></ActionButton>
          </FlagWrapper>
          <FlagWrapper>
            <h2><strong>Adviser Assist</strong> Programme</h2>
            Meeting the ad-hoc needs of our clients with expert advice and best of breed solutions.
            <ActionButton toRef="/services-for-clients#adviser-assist-programme">Find out <strong>More</strong></ActionButton>
          </FlagWrapper>
          {/*
          <FlagWrapper>
            <h2><strong>Self Serve</strong> Proposition</h2>
            Providing you with the tools to help control and manage your own financial affairs.
            <ActionButton toRef="/services-for-clients#self-serve-programme">Find out <strong>More</strong></ActionButton>
          </FlagWrapper>
          */}
        </FlexWrapper>


      </ScreenWrapper>
    </Layout>

  </>

)

export default IndexPage

export const pageQuery = graphql`
  query {
    royalCourtsImage: file(relativePath: { eq: "royal-courts-of-justice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 5031, quality:60) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
